import React, {useState, createRef} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';

import MoreMenu from '../menus/MoreMenu';
import DataFrameTableStateful from '../../components_stateful/tables/DataFrameTableStateful';

/*******************
CONSTANTS & GLOBALS
*******************/

const ingredientCategories = ['andere','groenten','koolhydraten','fruit','zuivel','vleesvervanger','noten','conserven','sauzen','kruiden']
const ingredientUnits = ['stuk(s)','gram','ml', 'andere']

const shoppingListHeadCells = [
  { id: 'checked', numeric: false, disablePadding: false, label: ''},
  { id: 'id', numberic: true, disablePadding: false, label: 'ID', hidden: true},
  { id: 'category', numeric: false, disablePadding: false, label: 'Category' },
  { id: 'ingredient', numeric: false, disablePadding: false, label: 'Ingredient' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
]

const useStyles = makeStyles(({
  root: {
    height: '100%',
  },
  // listItemTextPrimary: {
  //   width: '100%',
  //   fontSize:'0.8em',
  //   marginRight: '1em',
  // },
  textField: {
    marginTop: '1em',
    cursor: 'pointer',
  },
  moreMenu: {
    width: '5%',
    paddingLeft: '1em',
  },

}));

const ManuelIngredientsList = ({ ingredients, handleUpsert, handleRemove, handleClear, ...rest }) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [checked, setChecked]= useState(true)
  const [ingrID, setIngrID]= useState(true)
  const [category, setCategory] = useState('andere');
  const ingredientRef = createRef();  //use reference to avoid constant refreshing of component onChange
  const [ingredient, setIngredient] = useState('');
  const amountRef = createRef();  //use reference to avoid constant refreshing of component onChange
  const [amount, setAmount] = useState();
  const [unit, setUnit] = useState('stuk(s)');
  const [newItem, setNewItem] = useState(true)
  const [errorAmount, setErrorAmount] = useState(false)
  const [helperTextAmount, setHelperTextAmount] = useState('')
  const [errorIngredient, setErrorIngredient] = useState(false)
  const [helperTextIngredient, setHelperTextIngredient] = useState('')

  const clearState = () => {
    setChecked(true)
    setIngrID(-1)
    setCategory('andere')
    setIngredient('')
    setAmount('')
    setUnit('stuk(s)')
  }

  const handleOpenDialog = () => {
    setIngrID(-1)
    setCategory('andere');
    setIngredient('');
    setAmount('')
    setUnit('stuk(s)')
    setNewItem(true)
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setErrorAmount(false)
    setHelperTextAmount('')
    setErrorIngredient(false)
    setHelperTextIngredient('')
    setOpenDialog(false);
  };

  const handleSave = () => {
    if (validateAmount(amountRef.current.value) && validateIngredient(ingredientRef.current.value)) {
      let am = amountRef.current.value
      let ingr = ingredientRef.current.value
      handleUpsert({checked: checked, id:ingrID, category: category, ingredient: ingr.toLowerCase().trim(), amount: am.trim(), unit: unit});
      clearState()
      handleCloseDialog()
    }
  }

  const handleDelete = () => {
    handleRemove({category: category, id:ingrID, ingredient: ingredient, amount: amount, unit: unit})
    clearState()
    handleCloseDialog()
  };

  const handleTableRowClick = (event, row) => {
    if ('ingredient' in row) {
      if (event.target.type != 'checkbox') {
        //check if row has ingredient, otherwise table is empty
        setChecked(row.checked)
        setIngrID(row.id)
        setCategory(row.category);
        setIngredient(row.ingredient);
        setAmount(row.amount.split(" ")[0])
        setUnit(row.amount.split(" ")[1])
        setNewItem(false)
        setOpenDialog(true);
      }
      else {
        //checkbox was clicked
        handleUpsert({
          checked: !row.checked,
          id: row.id,
          category: row.category,
          ingredient: row.ingredient,
          amount: row.amount.split(" ")[0],
          unit: row.amount.split(" ")[1]
        });
      }
    }

  };

  const validateAmount = (amountRef) => {
    if (isNaN(amountRef) || !amountRef.replace(/\s/g, '').length) {
      setErrorAmount(true)
      setHelperTextAmount('U moet een getal invullen')
      return false
    }
    setErrorAmount(false)
    setHelperTextAmount('')
    return true
  }

  const validateIngredient = (ingredientRef) => {
    if (!ingredientRef.replace(/\s/g, '').length) {
      setErrorIngredient(true)
      setHelperTextIngredient('U moet een ingredient invullen')
      return false
    }
    setErrorIngredient(false)
    setHelperTextIngredient('')
    return true
  }

  return (
    <Card
      className={classes.root}
      {...rest}
    >
      <DataFrameTableStateful
        title="Manueel toevoegen"
        loadingData={false}
        headCells={shoppingListHeadCells}
        data={ingredients.length > 0 ? ingredients : [{category: 'Voeg ingrediënten toe indien gewenst'}]}
        selected={[]}
        handleClick={handleTableRowClick}
        orderByPrimary='category'
        orderBySecondary='ingredient'
      />
      <Divider />
      <Box
        display="flex"
        pb={4}
      >
        <Grid container justifyContent='flex-start'>
          {
            // <Button
            //   color="secondary"
            //   startIcon={<ArrowLeftIcon />}
            //   size="small"
            //   variant="text"
            //   onClick={handleClear}
            //   style={{textAlign: 'left'}}
            // >
            //   Verwijder alles
            // </Button>
          }

        </Grid>
        <Grid container justifyContent='flex-end'>
          <Button
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
            onClick={handleOpenDialog}
            style={{textAlign: 'right'}}
          >
            Rij Toevoegen
          </Button>
        </Grid>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>
          <div>
            <Typography variant="h3" component="h3" style={{textAlign: 'center'}}>
              Ingrediënt Toevoegen
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          {
            // <DialogContentText>
            //   Vul de informatie in en druk op opslaan.
            // </DialogContentText>
          }
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <TextField
              className={classes.textField}
              variant='outlined'
              value={category}
              onChange={(event) => setCategory(event.target.value)}
              select // tell TextField to render select
              label="Categorie"
            >
              {[...ingredientCategories].map((cat) => (
                <MenuItem key={`mu-${cat}`} value={cat}>{cat}</MenuItem>
              ))}
            </TextField>
            <TextField
              required
              className={classes.textField}
              variant='outlined'
              defaultValue={ingredient}
              label='Ingrediënt'
              // onChange={(event) => setIngredient(event.target.value)}  // causes the component to refresh (very slow on phone)
              inputRef={ingredientRef}
              error={errorIngredient}
              helperText={helperTextIngredient}
            />
            <TextField
              required
              className={classes.textField}
              variant='outlined'
              defaultValue={amount}
              label='Hoeveelheid'
              // onChange={(event) => setAmount(event.target.value)}  // causes the component to refresh (very slow on phone)
              inputRef={amountRef}
              error={errorAmount}
              helperText={helperTextAmount}
            />
            <TextField
              className={classes.textField}
              variant='outlined'
              value={unit}
              onChange={(event) => setUnit(event.target.value)}
              select // tell TextField to render select
              label="Eenheid"
            >
              {[...ingredientUnits].map((cat) => (
                <MenuItem key={`mu-${cat}`} value={cat}>{cat}</MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <DialogActions>
          {!newItem ? <Button onClick={handleDelete}>Verwijder</Button> : <div/>}
          <div style={{flex: '1 0 0'}} />
          <Button onClick={handleSave}>Opslaan</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

ManuelIngredientsList.propTypes = {
  className: PropTypes.string
};

export default ManuelIngredientsList;
